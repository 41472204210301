/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { multiplyScalarDependencies } from './dependenciesMultiplyScalar.generated.js';
import { numericDependencies } from './dependenciesNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createProd } from '../../factoriesAny.js';
export var prodDependencies = {
  multiplyScalarDependencies,
  numericDependencies,
  typedDependencies,
  createProd
};