/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { isNaNDependencies } from './dependenciesIsNaN.generated.js';
import { isNumericDependencies } from './dependenciesIsNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createMode } from '../../factoriesAny.js';
export var modeDependencies = {
  isNaNDependencies,
  isNumericDependencies,
  typedDependencies,
  createMode
};