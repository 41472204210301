/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { isNumericDependencies } from './dependenciesIsNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createHasNumericValue } from '../../factoriesAny.js';
export var hasNumericValueDependencies = {
  isNumericDependencies,
  typedDependencies,
  createHasNumericValue
};