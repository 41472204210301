/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ParserDependencies } from './dependenciesParserClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createParser } from '../../factoriesAny.js';
export var parserDependencies = {
  ParserDependencies,
  typedDependencies,
  createParser
};