/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { rotationMatrixDependencies } from './dependenciesRotationMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createRotate } from '../../factoriesAny.js';
export var rotateDependencies = {
  multiplyDependencies,
  rotationMatrixDependencies,
  typedDependencies,
  createRotate
};