/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { FractionDependencies } from './dependenciesFractionClass.generated.js';
import { complexDependencies } from './dependenciesComplex.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSign } from '../../factoriesAny.js';
export var signDependencies = {
  BigNumberDependencies,
  FractionDependencies,
  complexDependencies,
  typedDependencies,
  createSign
};