/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { numericDependencies } from './dependenciesNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createDivideScalar } from '../../factoriesAny.js';
export var divideScalarDependencies = {
  numericDependencies,
  typedDependencies,
  createDivideScalar
};