/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyScalarDependencies } from './dependenciesMultiplyScalar.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createKron } from '../../factoriesAny.js';
export var kronDependencies = {
  matrixDependencies,
  multiplyScalarDependencies,
  typedDependencies,
  createKron
};