/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { formatDependencies } from './dependenciesFormat.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createBin } from '../../factoriesAny.js';
export var binDependencies = {
  formatDependencies,
  typedDependencies,
  createBin
};