/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { equalDependencies } from './dependenciesEqual.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createDeepEqual } from '../../factoriesAny.js';
export var deepEqualDependencies = {
  equalDependencies,
  typedDependencies,
  createDeepEqual
};