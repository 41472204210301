/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { UnitDependencies } from './dependenciesUnitClass.generated.js';
import { createKlitzing } from '../../factoriesAny.js';
export var klitzingDependencies = {
  BigNumberDependencies,
  UnitDependencies,
  createKlitzing
};