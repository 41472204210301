/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { prodDependencies } from './dependenciesProd.generated.js';
import { sizeDependencies } from './dependenciesSize.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createCount } from '../../factoriesAny.js';
export var countDependencies = {
  prodDependencies,
  sizeDependencies,
  typedDependencies,
  createCount
};