/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { divideScalarDependencies } from './dependenciesDivideScalar.generated.js';
import { logDependencies } from './dependenciesLog.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLog1p } from '../../factoriesAny.js';
export var log1pDependencies = {
  ComplexDependencies,
  divideScalarDependencies,
  logDependencies,
  typedDependencies,
  createLog1p
};