/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSec } from '../../factoriesAny.js';
export var secDependencies = {
  BigNumberDependencies,
  typedDependencies,
  createSec
};