/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { UnitDependencies } from './dependenciesUnitClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createCreateUnit } from '../../factoriesAny.js';
export var createUnitDependencies = {
  UnitDependencies,
  typedDependencies,
  createCreateUnit
};