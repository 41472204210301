/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addDependencies } from './dependenciesAdd.generated.js';
import { numericDependencies } from './dependenciesNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSumTransform } from '../../factoriesAny.js';
export var sumTransformDependencies = {
  addDependencies,
  numericDependencies,
  typedDependencies,
  createSumTransform
};