/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addScalarDependencies } from './dependenciesAddScalar.generated.js';
import { conjDependencies } from './dependenciesConj.generated.js';
import { multiplyScalarDependencies } from './dependenciesMultiplyScalar.generated.js';
import { sizeDependencies } from './dependenciesSize.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createDot } from '../../factoriesAny.js';
export var dotDependencies = {
  addScalarDependencies,
  conjDependencies,
  multiplyScalarDependencies,
  sizeDependencies,
  typedDependencies,
  createDot
};