/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { bignumberDependencies } from './dependenciesBignumber.generated.js';
import { fractionDependencies } from './dependenciesFraction.generated.js';
import { numberDependencies } from './dependenciesNumber.generated.js';
import { createNumeric } from '../../factoriesAny.js';
export var numericDependencies = {
  bignumberDependencies,
  fractionDependencies,
  numberDependencies,
  createNumeric
};