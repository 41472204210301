/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSize } from '../../factoriesAny.js';
export var sizeDependencies = {
  matrixDependencies,
  typedDependencies,
  createSize
};