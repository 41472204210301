/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { DenseMatrixDependencies } from './dependenciesDenseMatrixClass.generated.js';
import { smallerDependencies } from './dependenciesSmaller.generated.js';
import { createImmutableDenseMatrixClass } from '../../factoriesAny.js';
export var ImmutableDenseMatrixDependencies = {
  DenseMatrixDependencies,
  smallerDependencies,
  createImmutableDenseMatrixClass
};