/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { largerDependencies } from './dependenciesLarger.generated.js';
import { smallerDependencies } from './dependenciesSmaller.generated.js';
import { createFibonacciHeapClass } from '../../factoriesAny.js';
export var FibonacciHeapDependencies = {
  largerDependencies,
  smallerDependencies,
  createFibonacciHeapClass
};