/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLog2 } from '../../factoriesAny.js';
export var log2Dependencies = {
  ComplexDependencies,
  typedDependencies,
  createLog2
};