/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { equalScalarDependencies } from './dependenciesEqualScalar.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createNthRoot } from '../../factoriesAny.js';
export var nthRootDependencies = {
  BigNumberDependencies,
  equalScalarDependencies,
  matrixDependencies,
  typedDependencies,
  createNthRoot
};