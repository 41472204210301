/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createAsin } from '../../factoriesAny.js';
export var asinDependencies = {
  ComplexDependencies,
  typedDependencies,
  createAsin
};