/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { conjDependencies } from './dependenciesConj.generated.js';
import { transposeDependencies } from './dependenciesTranspose.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createCtranspose } from '../../factoriesAny.js';
export var ctransposeDependencies = {
  conjDependencies,
  transposeDependencies,
  typedDependencies,
  createCtranspose
};