/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { compareNaturalDependencies } from './dependenciesCompareNatural.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSetSize } from '../../factoriesAny.js';
export var setSizeDependencies = {
  compareNaturalDependencies,
  typedDependencies,
  createSetSize
};