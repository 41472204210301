/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { compareDependencies } from './dependenciesCompare.generated.js';
import { compareNaturalDependencies } from './dependenciesCompareNatural.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSort } from '../../factoriesAny.js';
export var sortDependencies = {
  compareDependencies,
  compareNaturalDependencies,
  matrixDependencies,
  typedDependencies,
  createSort
};