/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { UnitDependencies } from './dependenciesUnitClass.generated.js';
import { createLoschmidt } from '../../factoriesAny.js';
export var loschmidtDependencies = {
  BigNumberDependencies,
  UnitDependencies,
  createLoschmidt
};