/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { isIntegerDependencies } from './dependenciesIsInteger.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createApply } from '../../factoriesAny.js';
export var applyDependencies = {
  isIntegerDependencies,
  typedDependencies,
  createApply
};