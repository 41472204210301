/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { AccessorNodeDependencies } from './dependenciesAccessorNode.generated.js';
import { ArrayNodeDependencies } from './dependenciesArrayNode.generated.js';
import { ConstantNodeDependencies } from './dependenciesConstantNode.generated.js';
import { FunctionNodeDependencies } from './dependenciesFunctionNode.generated.js';
import { IndexNodeDependencies } from './dependenciesIndexNode.generated.js';
import { ObjectNodeDependencies } from './dependenciesObjectNode.generated.js';
import { OperatorNodeDependencies } from './dependenciesOperatorNode.generated.js';
import { ParenthesisNodeDependencies } from './dependenciesParenthesisNode.generated.js';
import { SymbolNodeDependencies } from './dependenciesSymbolNode.generated.js';
import { addDependencies } from './dependenciesAdd.generated.js';
import { divideDependencies } from './dependenciesDivide.generated.js';
import { equalDependencies } from './dependenciesEqual.generated.js';
import { isZeroDependencies } from './dependenciesIsZero.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { powDependencies } from './dependenciesPow.generated.js';
import { subtractDependencies } from './dependenciesSubtract.generated.js';
import { createSimplifyCore } from '../../factoriesAny.js';
export var simplifyCoreDependencies = {
  AccessorNodeDependencies,
  ArrayNodeDependencies,
  ConstantNodeDependencies,
  FunctionNodeDependencies,
  IndexNodeDependencies,
  ObjectNodeDependencies,
  OperatorNodeDependencies,
  ParenthesisNodeDependencies,
  SymbolNodeDependencies,
  addDependencies,
  divideDependencies,
  equalDependencies,
  isZeroDependencies,
  multiplyDependencies,
  powDependencies,
  subtractDependencies,
  createSimplifyCore
};