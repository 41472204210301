/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addDependencies } from './dependenciesAdd.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createTrace } from '../../factoriesAny.js';
export var traceDependencies = {
  addDependencies,
  matrixDependencies,
  typedDependencies,
  createTrace
};