/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { numberDependencies } from './dependenciesNumber.generated.js';
import { subtractDependencies } from './dependenciesSubtract.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createDiff } from '../../factoriesAny.js';
export var diffDependencies = {
  matrixDependencies,
  numberDependencies,
  subtractDependencies,
  typedDependencies,
  createDiff
};