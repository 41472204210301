/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { MatrixDependencies } from './dependenciesMatrixClass.generated.js';
import { equalScalarDependencies } from './dependenciesEqualScalar.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSparseMatrixClass } from '../../factoriesAny.js';
export var SparseMatrixDependencies = {
  MatrixDependencies,
  equalScalarDependencies,
  typedDependencies,
  createSparseMatrixClass
};