/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { FractionDependencies } from './dependenciesFractionClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createFraction } from '../../factoriesAny.js';
export var fractionDependencies = {
  FractionDependencies,
  typedDependencies,
  createFraction
};