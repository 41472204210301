/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { SparseMatrixDependencies } from './dependenciesSparseMatrixClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSparse } from '../../factoriesAny.js';
export var sparseDependencies = {
  SparseMatrixDependencies,
  typedDependencies,
  createSparse
};