/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { isZeroDependencies } from './dependenciesIsZero.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createMatrixFromFunction } from '../../factoriesAny.js';
export var matrixFromFunctionDependencies = {
  isZeroDependencies,
  matrixDependencies,
  typedDependencies,
  createMatrixFromFunction
};