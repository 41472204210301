/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { NodeDependencies } from './dependenciesNode.generated.js';
import { subsetDependencies } from './dependenciesSubset.generated.js';
import { createAccessorNode } from '../../factoriesAny.js';
export var AccessorNodeDependencies = {
  NodeDependencies,
  subsetDependencies,
  createAccessorNode
};