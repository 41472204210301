/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { subtractDependencies } from './dependenciesSubtract.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createCross } from '../../factoriesAny.js';
export var crossDependencies = {
  matrixDependencies,
  multiplyDependencies,
  subtractDependencies,
  typedDependencies,
  createCross
};