/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { absDependencies } from './dependenciesAbs.generated.js';
import { addDependencies } from './dependenciesAdd.generated.js';
import { identityDependencies } from './dependenciesIdentity.generated.js';
import { invDependencies } from './dependenciesInv.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createExpm } from '../../factoriesAny.js';
export var expmDependencies = {
  absDependencies,
  addDependencies,
  identityDependencies,
  invDependencies,
  multiplyDependencies,
  typedDependencies,
  createExpm
};