/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { NodeDependencies } from './dependenciesNode.generated.js';
import { RangeDependencies } from './dependenciesRangeClass.generated.js';
import { sizeDependencies } from './dependenciesSize.generated.js';
import { createIndexNode } from '../../factoriesAny.js';
export var IndexNodeDependencies = {
  NodeDependencies,
  RangeDependencies,
  sizeDependencies,
  createIndexNode
};