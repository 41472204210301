/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { isIntegerDependencies } from './dependenciesIsInteger.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createReshape } from '../../factoriesAny.js';
export var reshapeDependencies = {
  isIntegerDependencies,
  matrixDependencies,
  typedDependencies,
  createReshape
};