/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { HelpDependencies } from './dependenciesHelpClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createHelp } from '../../factoriesAny.js';
export var helpDependencies = {
  HelpDependencies,
  typedDependencies,
  createHelp
};