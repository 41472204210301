/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { factorialDependencies } from './dependenciesFactorial.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createPermutations } from '../../factoriesAny.js';
export var permutationsDependencies = {
  factorialDependencies,
  typedDependencies,
  createPermutations
};