/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ChainDependencies } from './dependenciesChainClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createChain } from '../../factoriesAny.js';
export var chainDependencies = {
  ChainDependencies,
  typedDependencies,
  createChain
};