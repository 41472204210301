/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLog10 } from '../../factoriesAny.js';
export var log10Dependencies = {
  ComplexDependencies,
  typedDependencies,
  createLog10
};