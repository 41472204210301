/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { NodeDependencies } from './dependenciesNode.generated.js';
import { subsetDependencies } from './dependenciesSubset.generated.js';
import { createAssignmentNode } from '../../factoriesAny.js';
export var AssignmentNodeDependencies = {
  matrixDependencies,
  NodeDependencies,
  subsetDependencies,
  createAssignmentNode
};