/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { UnitDependencies } from './dependenciesUnitClass.generated.js';
import { createCoulomb } from '../../factoriesAny.js';
export var coulombDependencies = {
  BigNumberDependencies,
  UnitDependencies,
  createCoulomb
};