/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { bignumberDependencies } from './dependenciesBignumber.generated.js';
import { fractionDependencies } from './dependenciesFraction.generated.js';
import { AccessorNodeDependencies } from './dependenciesAccessorNode.generated.js';
import { ArrayNodeDependencies } from './dependenciesArrayNode.generated.js';
import { ConstantNodeDependencies } from './dependenciesConstantNode.generated.js';
import { FunctionNodeDependencies } from './dependenciesFunctionNode.generated.js';
import { IndexNodeDependencies } from './dependenciesIndexNode.generated.js';
import { ObjectNodeDependencies } from './dependenciesObjectNode.generated.js';
import { OperatorNodeDependencies } from './dependenciesOperatorNode.generated.js';
import { ParenthesisNodeDependencies } from './dependenciesParenthesisNode.generated.js';
import { SymbolNodeDependencies } from './dependenciesSymbolNode.generated.js';
import { addDependencies } from './dependenciesAdd.generated.js';
import { divideDependencies } from './dependenciesDivide.generated.js';
import { equalDependencies } from './dependenciesEqual.generated.js';
import { isZeroDependencies } from './dependenciesIsZero.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { multiplyDependencies } from './dependenciesMultiply.generated.js';
import { parseDependencies } from './dependenciesParse.generated.js';
import { powDependencies } from './dependenciesPow.generated.js';
import { resolveDependencies } from './dependenciesResolve.generated.js';
import { simplifyCoreDependencies } from './dependenciesSimplifyCore.generated.js';
import { subtractDependencies } from './dependenciesSubtract.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createSimplify } from '../../factoriesAny.js';
export var simplifyDependencies = {
  bignumberDependencies,
  fractionDependencies,
  AccessorNodeDependencies,
  ArrayNodeDependencies,
  ConstantNodeDependencies,
  FunctionNodeDependencies,
  IndexNodeDependencies,
  ObjectNodeDependencies,
  OperatorNodeDependencies,
  ParenthesisNodeDependencies,
  SymbolNodeDependencies,
  addDependencies,
  divideDependencies,
  equalDependencies,
  isZeroDependencies,
  matrixDependencies,
  multiplyDependencies,
  parseDependencies,
  powDependencies,
  resolveDependencies,
  simplifyCoreDependencies,
  subtractDependencies,
  typedDependencies,
  createSimplify
};