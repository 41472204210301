/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { gammaDependencies } from './dependenciesGamma.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createFactorial } from '../../factoriesAny.js';
export var factorialDependencies = {
  gammaDependencies,
  typedDependencies,
  createFactorial
};