/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { NodeDependencies } from './dependenciesNode.generated.js';
import { SymbolNodeDependencies } from './dependenciesSymbolNode.generated.js';
import { createFunctionNode } from '../../factoriesAny.js';
export var FunctionNodeDependencies = {
  NodeDependencies,
  SymbolNodeDependencies,
  createFunctionNode
};