/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createAtanh } from '../../factoriesAny.js';
export var atanhDependencies = {
  ComplexDependencies,
  typedDependencies,
  createAtanh
};