/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { NodeDependencies } from './dependenciesNode.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createFunctionAssignmentNode } from '../../factoriesAny.js';
export var FunctionAssignmentNodeDependencies = {
  NodeDependencies,
  typedDependencies,
  createFunctionAssignmentNode
};