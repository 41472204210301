/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { UnitDependencies } from './dependenciesUnitClass.generated.js';
import { NodeDependencies } from './dependenciesNode.generated.js';
import { createSymbolNode } from '../../factoriesAny.js';
export var SymbolNodeDependencies = {
  UnitDependencies,
  NodeDependencies,
  createSymbolNode
};