/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { compareTextDependencies } from './dependenciesCompareText.generated.js';
import { isZeroDependencies } from './dependenciesIsZero.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createEqualText } from '../../factoriesAny.js';
export var equalTextDependencies = {
  compareTextDependencies,
  isZeroDependencies,
  typedDependencies,
  createEqualText
};