/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { FibonacciHeapDependencies } from './dependenciesFibonacciHeapClass.generated.js';
import { addScalarDependencies } from './dependenciesAddScalar.generated.js';
import { equalScalarDependencies } from './dependenciesEqualScalar.generated.js';
import { createSpaClass } from '../../factoriesAny.js';
export var SpaDependencies = {
  FibonacciHeapDependencies,
  addScalarDependencies,
  equalScalarDependencies,
  createSpaClass
};