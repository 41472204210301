/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { NodeDependencies } from './dependenciesNode.generated.js';
import { ResultSetDependencies } from './dependenciesResultSet.generated.js';
import { createBlockNode } from '../../factoriesAny.js';
export var BlockNodeDependencies = {
  NodeDependencies,
  ResultSetDependencies,
  createBlockNode
};