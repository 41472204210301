/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addDependencies } from './dependenciesAdd.generated.js';
import { divideDependencies } from './dependenciesDivide.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createMean } from '../../factoriesAny.js';
export var meanDependencies = {
  addDependencies,
  divideDependencies,
  typedDependencies,
  createMean
};