/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { parseDependencies } from './dependenciesParse.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLeafCount } from '../../factoriesAny.js';
export var leafCountDependencies = {
  parseDependencies,
  typedDependencies,
  createLeafCount
};