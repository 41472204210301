/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { absDependencies } from './dependenciesAbs.generated.js';
import { mapDependencies } from './dependenciesMap.generated.js';
import { medianDependencies } from './dependenciesMedian.generated.js';
import { subtractDependencies } from './dependenciesSubtract.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createMad } from '../../factoriesAny.js';
export var madDependencies = {
  absDependencies,
  mapDependencies,
  medianDependencies,
  subtractDependencies,
  typedDependencies,
  createMad
};