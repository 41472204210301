/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { addDependencies } from './dependenciesAdd.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { unaryPlusDependencies } from './dependenciesUnaryPlus.generated.js';
import { createCumSum } from '../../factoriesAny.js';
export var cumsumDependencies = {
  addDependencies,
  typedDependencies,
  unaryPlusDependencies,
  createCumSum
};