/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createTo } from '../../factoriesAny.js';
export var toDependencies = {
  matrixDependencies,
  typedDependencies,
  createTo
};