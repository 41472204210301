/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { largerDependencies } from './dependenciesLarger.generated.js';
import { numericDependencies } from './dependenciesNumeric.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createMax } from '../../factoriesAny.js';
export var maxDependencies = {
  largerDependencies,
  numericDependencies,
  typedDependencies,
  createMax
};