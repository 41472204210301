/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { compareDependencies } from './dependenciesCompare.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createCompareNatural } from '../../factoriesAny.js';
export var compareNaturalDependencies = {
  compareDependencies,
  typedDependencies,
  createCompareNatural
};