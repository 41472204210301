/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { ComplexDependencies } from './dependenciesComplexClass.generated.js';
import { divideScalarDependencies } from './dependenciesDivideScalar.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createLog } from '../../factoriesAny.js';
export var logDependencies = {
  ComplexDependencies,
  divideScalarDependencies,
  typedDependencies,
  createLog
};