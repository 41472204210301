/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createZeros } from '../../factoriesAny.js';
export var zerosDependencies = {
  BigNumberDependencies,
  matrixDependencies,
  typedDependencies,
  createZeros
};