/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { BigNumberDependencies } from './dependenciesBigNumberClass.generated.js';
import { createSQRT1_2 } from '../../factoriesAny.js'; // eslint-disable-line camelcase

export var SQRT1_2Dependencies = {
  // eslint-disable-line camelcase
  BigNumberDependencies,
  createSQRT1_2
};