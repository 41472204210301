/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { IndexDependencies } from './dependenciesIndexClass.generated.js';
import { matrixDependencies } from './dependenciesMatrix.generated.js';
import { rangeDependencies } from './dependenciesRange.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createColumn } from '../../factoriesAny.js';
export var columnDependencies = {
  IndexDependencies,
  matrixDependencies,
  rangeDependencies,
  typedDependencies,
  createColumn
};