/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
import { IndexDependencies } from './dependenciesIndexClass.generated.js';
import { typedDependencies } from './dependenciesTyped.generated.js';
import { createIndex } from '../../factoriesAny.js';
export var indexDependencies = {
  IndexDependencies,
  typedDependencies,
  createIndex
};